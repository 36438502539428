
// Bilty Types
export const COLLECT_BILTY_DATA = 'COLLECT_BILTY_DATA';
export const CLEAR_BILTY_DATA = 'CLEAR_BILTY_DATA';
export const GOODS_DATA = 'GOODS_DATA';
export const ADD_BILTY = 'ADD_BILTY';
export const QUOT_LIST = 'QUOT_LIST';
export const CREATE_QUOT_LIST = 'CREATE_QUOT_LIST';
export const CREATE_QUOT = 'CREATE_QUOT';
export const BILTY_NUMBER = 'BILTY_NUMBER';
export const SINGLE_BILTY_DATA = 'SINGLE_BILTY_DATA';
export const CLEAR_SINGLE_BILTY_DATA = 'CLEAR_SINGLE_BILTY_DATA';



// All Bilty Report
export const ALL_BILTY_DATA = 'ALL_BILTY_DATA';
export const TRACK_BILTY = 'TRACK_BILTY';

export const ALL_MEMO_DATA = 'ALL_MEMO_DATA';




export const BILL_DATA = 'BILL_DATA';
export const BILL_LIST = 'BILL_LIST';

// MEMO RECEIVE
export const GET_MEMO_RECEIVE = 'GET_MEMO_RECEIVE';
export const UPDATE_MEMO_RECEIVE = 'UPDATE_MEMO_RECEIVE';


export const PRINT_NUMBER = 'PRINT_NUMBER';





// Memo Types

export const ROUTE_DATA = 'ROUTE_DATA';
export const DELETE_ROW = 'DELETE_ROW';
export const UPDATE_ROW_DATA = 'UPDATE_ROW_DATA';
export const ADD_BILTY_TO_MEMO = 'ADD_BILTY_TO_MEMO';
export const SINGLE_MEMO_DATA = 'SINGLE_MEMO_DATA';
export const MEMO_PRINT_DATA = 'MEMO_PRINT_DATA';





export const DELETE_QUOT = 'DELETE_QUOT';
export const CREATE_PKG = 'CREATE_PKG';
export const GET_PKG = 'GET_PKG';





// Route
export const GET_ROUTE = 'GET_ROUTE';
export const CREATE_ROUTE = 'CREATE_ROUTE';
export const DELETE_ROUTE = 'DELETE_ROUTE';
export const EDIT_ROUTE = 'EDIT_ROUTE';








// CONSIGNOR TYPES
export const CREATE_NOR = 'CREATE_NOR';
export const GET_NOR = 'GET_NOR';
export const DELETE_NOR = 'DELETE_NOR';
export const EDIT_NOR = 'EDIT_NOR';
export const SET_NOR = 'SET_NOR';
export const SET_NEE = 'SET_NEE';

// CONSIGNEE TYPE
export const CREATE_NEE = 'CREATE_NEE';
export const GET_NEE = 'GET_NEE';
export const DELETE_NEE = 'DELETE_NEE';
export const EDIT_NEE = 'EDIT_NEE';




// CONSIGNEE TYPE
export const CREATE_CITY = 'CREATE_CITY';
export const GET_CITY = 'GET_CITY';
export const DELETE_CITY = 'DELETE_CITY';
export const EDIT_CITY = 'EDIT_CITY';




// DRIVER TYPE
export const GET_DRIVER = 'GET_DRIVER';
export const CREATE_DRIVER = 'CREATE_DRIVER';
export const EDIT_DRIVER = 'EDIT_DRIVER';
export const DELETE_DRIVER = 'DELETE_DRIVER';




// DRIVER TYPE
export const GET_VEHICLE = 'GET_VEHICLE';
export const CREATE_VEHICLE = 'CREATE_VEHICLE';
export const EDIT_VEHICLE = 'EDIT_VEHICLE';
export const DELETE_VEHICLE = 'DELETE_VEHICLE';





// Loading
export const Loading_API = 'Loading_API';


// Routes data

