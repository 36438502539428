import Loadable from '../../components/Loadable';
import { lazy } from 'react';

const NotFound = Loadable(lazy(() => import('./NotFound')));
const ForgotPassword = Loadable(lazy(() => import('./ForgotPassword')));
const JwtLogin = Loadable(lazy(() => import('./JwtLogin')));
const PrintBiltyForPDF = Loadable(lazy(() => import('../Bilty/biltydetails/PrintBiltyForPdf')));
// const JwtRegister = Loadable(lazy(() => import('./JwtRegister')));

const sessionRoutes = [
  // { path: '/session/signup', element: <JwtRegister /> },
  { path: '/session/signin', element: <JwtLogin /> },
  { path: '/session/forgot-password', element: <ForgotPassword /> },
  { path: '/session/404', element: <NotFound /> },
  { path: '/printBiltyforpdf', element: <PrintBiltyForPDF /> },
];

export default sessionRoutes;
